import { Injectable } from '@angular/core';
import { EventTypes } from './models/event-types';
import { ToastEvent } from './models/toast-event';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public toasts: ToastEvent[] = [];

  private show(toastEvent: ToastEvent) {
    this.toasts.push(toastEvent);
  }

  public showError(message = '') {
    this.show({
      message,
      type: EventTypes.Error,
    });
  }

  public showSuccess(message = '') {
    this.show({
      message,
      type: EventTypes.Success,
    });
  }

  public showWarning(message = '') {
    this.show({
      message,
      type: EventTypes.Warning,
    });
  }

  public showInfo(message = '') {
    this.show({
      message,
      type: EventTypes.Info,
    });
  }

  public remove(toast: ToastEvent) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  public clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
