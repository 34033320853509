import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { LoadingService } from '../../shared/loading/loading.service';
import { ToastService } from '../../shared/toast/toast.service';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticationState = new BehaviorSubject(false);
  public userData: any;
  isSuperUser: boolean = false;
  isSuperCustomer: boolean = false;
  public unidades: any[] = [];
  public unidadeSelecionada: any;
  token: string | undefined;

  http = inject(HttpClient);
  loadingService = inject(LoadingService);
  toastService = inject(ToastService);

  constructor() {
    this.checkTicket();
  }

  async login(user: string, password: string) {
    this.loadingService.show();

    try {
      const body = {
        email: user,
        senha: password,
      };

      const res = await lastValueFrom(
        this.http.post<any>(`${env.urlBase}/loginGesV2`, body)
      );

      if (res.data) {
        localStorage.setItem('userData', JSON.stringify(res));
        localStorage.setItem(TOKEN_KEY, res.ticket.ticket);
        this.setup(res);
        this.token = res.ticket.ticket;
        return this.authenticationState.next(true);
      } else {
        if (res.message == 'User or password not found') {
          this.toastService.showError('Usuário ou senha inválidos');
        }
      }
    } catch (error) {
      this.toastService.showError(
        'Erro de comunicação, tente novamente mais tarde.'
      );
      console.error(error);
    } finally {
      this.loadingService.hide();
    }
  }

  setup(dados: any) {
    console.log('setup', dados);
    this.userData = dados.data;
    this.token = dados.ticket.ticket;
    this.isSuperUser = this.userData.super === 1;
    this.isSuperCustomer = this.userData.super === 2;
    this.unidades = this.userData.unidades;
    this.unidadeSelecionada = this.unidades[0];
  }

  async checkTicket() {
    const userData = localStorage.getItem('userData');

    if (userData) {
      const userDataJson = JSON.parse(userData);
      this.setup(userDataJson);
      this.authenticationState.next(true);
    }
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  isSuper() {
    return this.isSuperUser;
  }

  logout() {
    localStorage.removeItem('userData');
    localStorage.removeItem('isSuperUser');
    localStorage.removeItem('unidades');
    localStorage.removeItem(TOKEN_KEY);
    this.authenticationState.next(false);
    window.location.href = '/';
  }

  getUnitsByIds(ids: number[]) {
    const units = this.unidades
      .filter((un) => ids.includes(un.id))
      .map((un) => un.nome);
    return units;
  }
}
